import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/distributors"

const Install = ({ data, location }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout location={location}>
      <Breadcrumbs title="Distribution" />
      <PageHeader title="Distribution" />
      <Content pageData={pageData} />
      <BanerContact />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxNTAzMg==" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfDistributors {
          distributorsContent {
            category
            title
            address
            email
            phone
            map {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`

export default Install
